<template>
	<!-- 监理周报 -->
	<div class="mod-config">
		<div ref="basic" style="height:20px;margin-top:-20px"></div>
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" :inline="true" class="border-form">
			<el-card style="margin-top: 20px; height: 100%;">
				<el-row>
					<el-input class="wn" v-model="dataForm.weeklyName" placeholder="请输入"
										v-if="basicVisible">
					</el-input>
					<span v-if="!basicVisible" style="font-weight: bold;font-size:16px">
						{{ dataForm.weeklyName }}
					</span>
				</el-row>
				<el-row>
					<div class="set_title">监理周报</div>
				</el-row>
				<el-row>
					<div class="set_date">{{dataForm.showWeeklyDate}}</div>
				</el-row>
				<el-row>
					<el-col :span="16">
						<el-row class="set_border">
							<el-col :span="12" class="col-set_border">
								<el-form-item label-width="120px" label="项目名称" class="item">
									<span class="set_span">
										{{ dataForm.projectInfo.projectName }}
									</span>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="120px" label="数量" class="item">
									<el-input class="sp-input" v-model="dataForm.projectNum" placeholder="请输入"
										v-if="basicVisible">
									</el-input>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.projectNum }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label-width="120px" label="委托方" class="item">
									<span class="set_span1">
										{{ dataForm.projectInfo.projectEntrust }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="12" class="col-set_border">
								<el-form-item label-width="120px" label="工程承包方" class="item">
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.projectInfo.contractCompany }}
									</span>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="120px" label="工程编号" class="item">
									<el-input class="sp-input" v-model="dataForm.projectSubjectNo" placeholder="请输入"
											v-if="basicVisible">
									</el-input>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.projectSubjectNo }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="12" class="col-set_border">
								<el-form-item label-width="120px" label="报告人" class="item">
									<el-input class="sp-input" v-model="dataForm.reportMan" placeholder="请输入"
											v-if="basicVisible">
									</el-input>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.reportMan }}
									</span>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="120px" label="时间" class="item">
									<!-- {{dataForm.refirmTime}} -->
									<el-date-picker v-model="dataForm.reportTime" type="date" value-format="yyyy-MM-dd"
										placeholder="选择日期" class="srt" v-if="basicVisible">
									</el-date-picker>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.reportTime }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="12" class="col-set_border">
								<el-form-item label-width="120px" label="项目总监" class="item">
									<el-input class="sp-input" v-model="dataForm.projectDirector" placeholder="请输入"
											v-if="basicVisible">
									</el-input>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.projectDirector }}
									</span>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label-width="120px" label="时间" class="item">
									<!-- {{dataForm.refirmTime}} -->
									<el-date-picker v-model="dataForm.projectDirectorTime" type="date" value-format="yyyy-MM-dd"
										placeholder="选择日期" class="srt" v-if="basicVisible">
									</el-date-picker>
									<span class="set_span" v-if="!basicVisible">
										{{ dataForm.projectDirectorTime }}
									</span>
								</el-form-item>
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="8">
						<i class="el-icon-edit-outline" @click="updateBasicHandle()" v-if="!basicVisible">
							<el-button type="text" size="small" style="font-size: 13px;"
							 :disabled="updateFlg || addFlg">修改</el-button>
						</i>
						<i class="el-icon-finished" @click="saveBasicHandle()" v-if="basicVisible">
							<el-button type="text" size="small" @click="saveBasicHandle()"
							 :disabled="updateFlg || addFlg">保存</el-button>
						</i>
						<i class="el-icon-takeaway-box" v-if="basicVisible" :disabled="updateFlg || addFlg"
							@click="cancelSaveBasicHandle()"> 
							<span style="font-size: 12px;margin-left:2px;">取消</span>
						</i>
					</el-col>
				</el-row>

				<div ref="overview"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">1. 概述</div>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">1.1 重要事项描述</span>
						</el-form-item>
					</el-col>
					<el-row>
						<draggable v-model="dataForm.projectDesc" @start="onDragStart('010100')" @update="onDragEnd">
							<el-col :span="24" v-for="(prodec, index) in dataForm.projectDesc" :key="index">
								<el-col :span="1">
									<i class="el-icon-position"></i>
								</el-col>
								<el-col :span="23" class="set_non" v-if="prodec.updUserName != undefined && prodec.updUserName != ''">
									<el-col :span="17" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
										<el-input class="desc_input1" type="textarea" autosize v-model="prodec.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span" v-else>
										{{prodec.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="prodec.updUserName != undefined && prodec.updUserName != '' && prodec.updDate != undefined && prodec.updDate != ''">
											{{ prodec.updUserName}} {{prodec.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(prodec)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && prodec.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(prodec.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && prodec.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(prodec.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
								<!-- 继承的数据添加背景色-->
								<el-col :span="23" class="set_bc" v-if="prodec.updUserName == undefined || prodec.updUserName == ''">
									<el-col :span="17" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
										<el-input class="desc_input_bc" type="textarea" autosize v-model="prodec.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span_color" v-else>
										{{prodec.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="prodec.updUserName != undefined && prodec.updUserName != '' && prodec.updDate != undefined && prodec.updDate != ''">
											{{ prodec.updUserName}} {{prodec.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(prodec)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="prodec.recNo == '' || (updateFlg && prodec.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(prodec.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(prodec.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
							</el-col>
						</draggable>
						<el-col :span="24" style="margin-top:10px">
							<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
							style="background-color: #00428e;" @click="addProjectDescItem()">追加事项描述</el-button>
						</el-col>
					</el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspanDetail">1.2 本周工程质量状况</span>
							<el-radio-group v-model="dataForm.qualitySituation" @change="saveBasicHandle()" :disabled="updateFlg || addFlg || basicVisible">
								<el-radio label="0">正常</el-radio>
								<el-radio label="1">不正常</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:85px">1.3 本周发出监理联系单 共</span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.contactNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:85px">1.4 本周发出监理通知单 共</span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.noticeNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:85px">1.5 本周发出不一致报告 共</span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.disAccordNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan" style="margin-right:127px">1.6 本周文件审查 共</span>
							<div class="summaryvalueSpan1" >
								<span class="summaryvalue1"> {{dataForm.fileCheckNum}} </span>
								<span>份</span>
                            </div>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">1.7 本周检验项目</span>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-table class="dataListTable" :data="dataForm.testItemRecords" header-align="center" border
							style="width: 100%" header-cell-class-name="dataListTHeader"
							:cell-style="{ padding: '0px' }">
							<el-table-column prop="subjectNo" label="船号" width="120" align="center">
							</el-table-column>
							<el-table-column prop="majorName" label="专业" width="120" align="center">
							</el-table-column>
							<el-table-column prop="testConclusionCount" label="接受" align="center">
							</el-table-column>
							<el-table-column prop="testConclusionWhereCount" label="有条件接受 " align="center">
							</el-table-column>
							<el-table-column prop="recheckAfterCorrectionCount" label="修正后复检 " align="center">
							</el-table-column>
							<el-table-column prop="testConclusionRefuseCount" label="不接受" align="center">
							</el-table-column>
							<el-table-column prop="testConclusionTotalCount" label="总计 " align="center">
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">1.8 巡检整改项目</span>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-table class="dataListTable" :data="dataForm.patrolItemRecords" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader"
							:cell-style="{ padding: '0px' }">
							<el-table-column prop="subjectNo" label="船号" width="120" align="center">
							</el-table-column>
							<el-table-column prop="majorName" label="专业" width="120" align="center">
							</el-table-column>
							<el-table-column prop="applyNum" label="本周内提出整改数" align="center">
							</el-table-column>
							<el-table-column prop="alreadyModifyNum" label="截至本周已整改数" align="center">
							</el-table-column>
							<el-table-column prop="notFinishNum" label="截止本周未整改数 " align="center">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<div ref="progress"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">2. 施工进度情况</div>
				<el-row>
					<el-col :span="20">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.1 分段进度表</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" style="padding-bottom: 10px">
						<el-table class="dataListTable" :data="dataForm.sectionalItemRecords" header-align="center"
							border style="width: 100%;" header-cell-class-name="dataListTHeader"
							:cell-style="{ padding: '0px' }">
							<el-table-column prop="projectNo" label="工程编号" align="center">
							</el-table-column>
							<el-table-column prop="totalNum" label="分段总数" align="center">
							</el-table-column>
							<el-table-column prop="upNum" label="上胎架数量" align="center">
							</el-table-column>
							<el-table-column prop="checkNum" label="验收完成数" align="center">
							</el-table-column>
							<el-table-column prop="paintingNum" label="涂装完工数" align="center">
							</el-table-column>
							<el-table-column prop="upShipNum" label="上船台总装数" align="center">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="20">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.2 系泊试验进度表</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24" style="margin-bottom: 10px;">
						<el-table class="dataListTable" :data="dataForm.mooringTestItemRecords" header-align="center"
							border style="width: 100%;" header-cell-class-name="dataListTHeader"
							:cell-style="{ padding: '0px' }">
							<el-table-column prop="projectNo" label="工程编号" align="center">
							</el-table-column>
							<el-table-column prop="testItemNum" label="系泊试验项目总数" align="center">
							</el-table-column>
							<el-table-column prop="testFinishItemNum" label="系泊试验完成总数" align="center">
							</el-table-column>
							<el-table-column prop="shipFinishItemNum" label="船体系泊试验项目完成数" align="center">
							</el-table-column>
							<el-table-column prop="turbineFinishItemNum" label="轮机系泊试验项目完成数" align="center">
							</el-table-column>
							<el-table-column prop="electricalFinishItemNum" label="电气系泊试验项目完成数" align="center">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.3 详细进度情况</span>
						</el-form-item>
					</el-col>
					<el-row v-for="(conProgress, index) in dataForm.constructionProgress" :key="index">
							<el-col :span="24" class="set_sub">{{ conProgress.subjectName }}</el-col>
							<el-row v-for="(conPro, index1) in conProgress.conProgressList" :key="index1">
								<el-row>
									<el-col class="conp">{{ conPro.majorName }}</el-col>
								</el-row>
								<draggable v-model="conPro.descProgressList" @start="onDragStart('020300', index, index1)" @update="onDragEnd">
									<el-row v-for="(progress, index2) in conPro.descProgressList" :key="index2">
										<el-col :span="1">
											<i class="el-icon-position"></i>
										</el-col>
										<el-col :span="23" class="set_non" v-if="progress.updUserName != undefined && progress.updUserName != ''">
											<el-col :span="17" v-if="progress.recNo == '' || (updateFlg && progress.recNo == updateDescRecNo)">
												<el-input class="desc_input1" type="textarea" autosize v-model="progress.descContent"></el-input>
											</el-col>
											<el-col :span="17" class="desc_span" v-else>
												{{progress.descContent}}
											</el-col>
											<el-col :span="4" class="un_col">
												<span class="un_span" v-if="progress.updUserName != undefined && progress.updUserName != '' && progress.updDate != undefined && progress.updDate != ''">
													{{ progress.updUserName}} {{progress.updDate}}
												</span>
											</el-col>
											<!-- 占位 -->
											<!-- <el-col :span="1" > -->
												<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
											<!-- </el-col> -->
											<!--新增和修改时按钮操作-->
											<el-col :span="1" v-if="progress.recNo == '' || (updateFlg && progress.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="saveDescHandle(progress)"> 保存 </el-button>
											</el-col>
											<el-col :span="1" v-if="progress.recNo == '' || (updateFlg && progress.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="cancelDescHandle()"> 取消 </el-button>
											</el-col>
											<!--非新增和修改时按钮操作-->
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && progress.showButton == 1">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="updateDescHandle(progress.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
											</el-col>
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && progress.showButton == 1">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="deleteDescHandle(progress.recNo)"> × 删除</el-button>
											</el-col>
										</el-col>
										<!-- 继承的数据添加背景色-->
										<el-col :span="23" class="set_bc" v-if="progress.updUserName == undefined || progress.updUserName == ''">
											<el-col :span="17" v-if="progress.recNo == '' || (updateFlg && progress.recNo == updateDescRecNo)">
												<el-input class="desc_input_bc" type="textarea" autosize v-model="progress.descContent"></el-input>
											</el-col>
											<el-col :span="17" class="desc_span_color" v-else>
												{{progress.descContent}}
											</el-col>
											<el-col :span="4" class="un_col">
												<span class="un_span" v-if="progress.updUserName != undefined && progress.updUserName != '' && progress.updDate != undefined && progress.updDate != ''">
													{{ progress.updUserName}} {{progress.updDate}}
												</span>
											</el-col>
											<!-- 占位 -->
											<!-- <el-col :span="1" > -->
												<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
											<!-- </el-col> -->
											<!--新增和修改时按钮操作-->
											<el-col :span="1" v-if="progress.recNo == '' || (updateFlg && progress.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="saveDescHandle(progress)"> 保存 </el-button>
											</el-col>
											<el-col :span="1" v-if="progress.recNo == '' || (updateFlg && progress.recNo == updateDescRecNo)">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="cancelDescHandle()"> 取消 </el-button>
											</el-col>
											<!--非新增和修改时按钮操作-->
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="updateDescHandle(progress.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
											</el-col>
											<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
												<el-button v-preventReClick type="text" size="small" class="desc_btn"
												@click="deleteDescHandle(progress.recNo)"> × 删除</el-button>
											</el-col>
										</el-col>
									</el-row>
								</draggable>
								<el-col :span="24" style="margin-top:10px">
									<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible" 
									style="background-color: #00428e;" @click="addConstructionProgressItem(conProgress.subjectNo, conPro.major, index, index1)">追加</el-button>
							</el-col>
							</el-row>
						</el-row>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">2.4 进度照片</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(progressPhotoRecord, idxs) in dataForm.progressPhotoRecords">
					<el-col :span="24" class="set_sub">{{ progressPhotoRecord.projectNo }}</el-col>
					<el-button v-preventReClick  type="primary" size="small" :disabled="updateFlg || addFlg || basicVisible" 
					 	style="margin-bottom: 10px; background-color: #00428e;" @click="handleProgressPhotoSelect(progressPhotoRecord, idxs)">选择照片</el-button>
					
					<el-col :span="24">
						<div class="card-container">
							<div class="card-row" v-for="(row, rowIndex) in cardRows(progressPhotoRecord.photoList)"
								:key="rowIndex">
								<el-row>
									<div class="card" v-for="(item, colIndex) in row" :key="item.recNo"
										@dragstart="dragStart('progress', idxs, rowIndex, colIndex)" @dragover.prevent
										@drop="drop('progress', idxs, rowIndex, colIndex)" @dragend="dragEnd()"
										draggable="true">
										<el-col :span="4">
											<el-card :body-style="{ padding: '0px' }"
												style="margin-bottom: 10px;margin-right: 5px;height: 285px;">
												<el-image :src="item.photoPathUri" fit="scale-down"
													class="image"></el-image>
												<div style="padding: 2px 10px;">
													<div class="t-column-ellipsis">
														<span>{{ item.photoContent }}</span>
													</div>
													<div class="bottom clearfix">
														<el-button type="text" class="button" :disabled="updateFlg || addFlg || basicVisible"
															@click="delImg(item.recNo)">删除</el-button>
														<el-button type="text" class="button" :disabled="updateFlg || addFlg || basicVisible"
															style="margin-right: 10px;"
															@click="updateImgContent(item.recNo, item.photoContent)">修改</el-button>
													</div>
												</div>
											</el-card>
										</el-col>
									</div>
								</el-row>
							</div>
						</div>
					</el-col>
				</el-row>


				<el-row>
					<div v-if="!dataForm.progressPhotoRecords || dataForm.progressPhotoRecords.length === 0">
						<span style="color: #e7b243;;font-size: 14px;">暂无进度照片</span>
					</div>
				</el-row>
				<div ref="quality"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">3. 质量情况</div>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.1 停止点检查（报验）情况</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.1.1 总体描述</span>
						</el-form-item>
					</el-col>
					<el-row>
						<draggable v-model="dataForm.stopPointCheckDesc" @start="onDragStart('030101')" @update="onDragEnd">
							<el-col :span="24" v-for="(stopdesc, index) in dataForm.stopPointCheckDesc" :key="index">
								<el-col :span="1">
									<i class="el-icon-position"></i>
								</el-col>
								<el-col :span="23" class="set_non" v-if="stopdesc.updUserName != undefined && stopdesc.updUserName != ''">
									<el-col :span="17" v-if="stopdesc.recNo == '' || (updateFlg && stopdesc.recNo == updateDescRecNo)">
										<el-input class="desc_input1" type="textarea" autosize v-model="stopdesc.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span" v-else>
										{{stopdesc.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="stopdesc.updUserName != undefined && stopdesc.updUserName != '' && stopdesc.updDate != undefined && stopdesc.updDate != ''">
											{{ stopdesc.updUserName}} {{stopdesc.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="stopdesc.recNo == '' || (updateFlg && stopdesc.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(stopdesc)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="stopdesc.recNo == '' || (updateFlg && stopdesc.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && stopdesc.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(stopdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && stopdesc.showButton == 1">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(stopdesc.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
								<!-- 继承的数据添加背景色-->
								<el-col :span="23" class="set_bc" v-if="stopdesc.updUserName == undefined || stopdesc.updUserName == ''">
									<el-col :span="17" v-if="stopdesc.recNo == '' || (updateFlg && stopdesc.recNo == updateDescRecNo)">
										<el-input class="desc_input_bc" type="textarea" autosize v-model="stopdesc.descContent"></el-input>
									</el-col>
									<el-col :span="17" class="desc_span_color" v-else>
										{{stopdesc.descContent}}
									</el-col>
									<el-col :span="4" class="un_col">
										<span class="un_span" v-if="stopdesc.updUserName != undefined && stopdesc.updUserName != '' && stopdesc.updDate != undefined && stopdesc.updDate != ''">
											{{ stopdesc.updUserName}} {{stopdesc.updDate}}
										</span>
									</el-col>
									<!-- 占位 -->
									<!-- <el-col :span="1" > -->
										<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
									<!-- </el-col> -->
									<!--新增和修改时按钮操作-->
									<el-col :span="1" v-if="stopdesc.recNo == '' || (updateFlg && stopdesc.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="saveDescHandle(stopdesc)"> 保存 </el-button>
									</el-col>
									<el-col :span="1" v-if="stopdesc.recNo == '' || (updateFlg && stopdesc.recNo == updateDescRecNo)">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="cancelDescHandle()"> 取消 </el-button>
									</el-col>
									<!--非新增和修改时按钮操作-->
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="updateDescHandle(stopdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
									</el-col>
									<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
										<el-button v-preventReClick type="text" size="small" class="desc_btn"
										@click="deleteDescHandle(stopdesc.recNo)"> × 删除</el-button>
									</el-col>
								</el-col>
							</el-col>
						</draggable>
						<el-col :span="24" style="margin-top:10px">
							<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
							style="background-color: #00428e;" @click="addStopPointCheckDescItem()">追加总体描述</el-button>
						</el-col>
					</el-row>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.1.2 主要质量问题跟踪处理情况</span>
						</el-form-item>
					</el-col>
					<el-row :span="24" style="padding-bottom: 10px">
						<el-row v-for="(qualityProblemDesc, index) in dataForm.qualityProblemDescList">
							 <el-col :span="24" class="set_sub">{{ qualityProblemDesc.subjectName }}</el-col>
							 <draggable v-model="qualityProblemDesc.reportDescInfos" @start="onDragStart('030102',index, '')" @update="onDragEnd">
								<el-col :span="24" v-for="(qualityproblemdesc, index1) in qualityProblemDesc.reportDescInfos" :key="index1">
									<el-col :span="1">
										<i class="el-icon-position"></i>
									</el-col>
									<el-col :span="23" class="set_non" v-if="qualityproblemdesc.updUserName != undefined && qualityproblemdesc.updUserName != ''">
										<el-col :span="17" v-if="qualityproblemdesc.recNo == '' || (updateFlg && qualityproblemdesc.recNo == updateDescRecNo)">
											<el-input class="desc_input1" type="textarea" autosize v-model="qualityproblemdesc.descContent"></el-input>
										</el-col>
										<el-col :span="17" class="desc_span" v-else>
											{{qualityproblemdesc.descContent}}
										</el-col>
										<el-col :span="4" class="un_col">
											<span class="un_span" v-if="qualityproblemdesc.updUserName != undefined && qualityproblemdesc.updUserName != '' && qualityproblemdesc.updDate != undefined && qualityproblemdesc.updDate != ''">
												{{ qualityproblemdesc.updUserName}} {{qualityproblemdesc.updDate}}
											</span>
										</el-col>
										<!-- 占位 -->
										<!-- <el-col :span="1" > -->
											<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
										<!-- </el-col> -->
										<!--新增和修改时按钮操作-->
										<el-col :span="1" v-if="qualityproblemdesc.recNo == '' || (updateFlg && qualityproblemdesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="saveDescHandle(qualityproblemdesc)"> 保存 </el-button>
										</el-col>
										<el-col :span="1" v-if="qualityproblemdesc.recNo == '' || (updateFlg && qualityproblemdesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="cancelDescHandle()"> 取消 </el-button>
										</el-col>
										<!--非新增和修改时按钮操作-->
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && qualityproblemdesc.showButton == 1">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="updateDescHandle(qualityproblemdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
										</el-col>
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && qualityproblemdesc.showButton == 1">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="deleteDescHandle(qualityproblemdesc.recNo)"> × 删除</el-button>
										</el-col>
									</el-col>
									<!-- 继承的数据添加背景色-->
									<el-col :span="23" class="set_bc" v-if="qualityproblemdesc.updUserName == undefined || qualityproblemdesc.updUserName == ''">
										<el-col :span="17" v-if="qualityproblemdesc.recNo == '' || (updateFlg && qualityproblemdesc.recNo == updateDescRecNo)">
											<el-input class="desc_input_bc" type="textarea" autosize v-model="qualityproblemdesc.descContent"></el-input>
										</el-col>
										<el-col :span="17" class="desc_span_color" v-else>
											{{qualityproblemdesc.descContent}}
										</el-col>
										<el-col :span="4" class="un_col">
											<span class="un_span" v-if="qualityproblemdesc.updUserName != undefined && qualityproblemdesc.updUserName != '' && qualityproblemdesc.updDate != undefined && qualityproblemdesc.updDate != ''">
												{{ qualityproblemdesc.updUserName}} {{qualityproblemdesc.updDate}}
											</span>
										</el-col>
										<!-- 占位 -->
										<!-- <el-col :span="1" > -->
											<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
										<!-- </el-col> -->
										<!--新增和修改时按钮操作-->
										<el-col :span="1" v-if="qualityproblemdesc.recNo == '' || (updateFlg && qualityproblemdesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="saveDescHandle(qualityproblemdesc)"> 保存 </el-button>
										</el-col>
										<el-col :span="1" v-if="qualityproblemdesc.recNo == '' || (updateFlg && qualityproblemdesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="cancelDescHandle()"> 取消 </el-button>
										</el-col>
										<!--非新增和修改时按钮操作-->
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="updateDescHandle(qualityproblemdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
										</el-col>
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="deleteDescHandle(qualityproblemdesc.recNo)"> × 删除</el-button>
										</el-col>
									</el-col>
								</el-col>
							</draggable>
							<el-col :span="24" style="margin-top:10px">
								<el-button type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible" style="background-color: #00428e;"
									@click="addQualityProblemDescItem(qualityProblemDesc.subjectNo)">追加处理情况</el-button>
							</el-col>
						</el-row>
					</el-row>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.2 巡检检查情况</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.2.1 总体描述</span>
						</el-form-item>
					</el-col>
					<draggable v-model="dataForm.patrolCheckDesc" @start="onDragStart('030201')" @update="onDragEnd">
						<el-col :span="24" v-for="(patroldesc, index) in dataForm.patrolCheckDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non" v-if="patroldesc.updUserName != undefined && patroldesc.updUserName != ''">
								<el-col :span="17" v-if="patroldesc.recNo == '' || (updateFlg && patroldesc.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="patroldesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{patroldesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="patroldesc.updUserName != undefined && patroldesc.updUserName != '' && patroldesc.updDate != undefined && patroldesc.updDate != ''">
										{{ patroldesc.updUserName}} {{patroldesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="patroldesc.recNo == '' || (updateFlg && patroldesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(patroldesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="patroldesc.recNo == '' || (updateFlg && patroldesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && patroldesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(patroldesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && patroldesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(patroldesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
							<!-- 继承的数据添加背景色-->
							<el-col :span="23" class="set_bc" v-if="patroldesc.updUserName == undefined || patroldesc.updUserName == ''">
								<el-col :span="17" v-if="patroldesc.recNo == '' || (updateFlg && patroldesc.recNo == updateDescRecNo)">
									<el-input class="desc_input_bc" type="textarea" autosize v-model="patroldesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span_color" v-else>
									{{patroldesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="patroldesc.updUserName != undefined && patroldesc.updUserName != '' && patroldesc.updDate != undefined && patroldesc.updDate != ''">
										{{ patroldesc.updUserName}} {{patroldesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="patroldesc.recNo == '' || (updateFlg && patroldesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(patroldesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="patroldesc.recNo == '' || (updateFlg && patroldesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(patroldesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(patroldesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-top:10px">
						<el-button type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
						style="background-color: #00428e;" @click="addPatrolCheckDescItem()">追加总体描述</el-button>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.2.2 突出问题跟踪处理情况</span>
						</el-form-item>
					</el-col>
					<el-col :span="24" style="padding-bottom: 10px">
						<el-row v-for="(prominentProblemDesc, index) in dataForm.prominentProblemDescList" :key="index">
							 <el-col :span="24" class="set_sub">{{ prominentProblemDesc.subjectName }}</el-col>
							 <draggable v-model="prominentProblemDesc.reportDescInfos" @start="onDragStart('030202',index, '')" @update="onDragEnd">
								<el-col :span="24" v-for="(prominentproblemdesc, index1) in prominentProblemDesc.reportDescInfos" :key="index1">
									<el-col :span="1">
										<i class="el-icon-position"></i>
									</el-col>
									<el-col :span="23" class="set_non" v-if="prominentproblemdesc.updUserName != undefined && prominentproblemdesc.updUserName != ''">
										<el-col :span="17" v-if="prominentproblemdesc.recNo == '' || (updateFlg && prominentproblemdesc.recNo == updateDescRecNo)">
											<el-input class="desc_input1" type="textarea" autosize v-model="prominentproblemdesc.descContent"></el-input>
										</el-col>
										<el-col :span="17" class="desc_span" v-else>
											{{prominentproblemdesc.descContent}}
										</el-col>
										<el-col :span="4" class="un_col">
											<span class="un_span" v-if="prominentproblemdesc.updUserName != undefined && prominentproblemdesc.updUserName != '' && prominentproblemdesc.updDate != undefined && prominentproblemdesc.updDate != ''">
												{{ prominentproblemdesc.updUserName}} {{prominentproblemdesc.updDate}}
											</span>
										</el-col>
										<!-- 占位 -->
										<!-- <el-col :span="1" > -->
											<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
										<!-- </el-col> -->
										<!--新增和修改时按钮操作-->
										<el-col :span="1" v-if="prominentproblemdesc.recNo == '' || (updateFlg && prominentproblemdesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="saveDescHandle(prominentproblemdesc)"> 保存 </el-button>
										</el-col>
										<el-col :span="1" v-if="prominentproblemdesc.recNo == '' || (updateFlg && prominentproblemdesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="cancelDescHandle()"> 取消 </el-button>
										</el-col>
										<!--非新增和修改时按钮操作-->
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && prominentproblemdesc.showButton == 1">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="updateDescHandle(prominentproblemdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
										</el-col>
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && prominentproblemdesc.showButton == 1">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="deleteDescHandle(prominentproblemdesc.recNo)"> × 删除</el-button>
										</el-col>
									</el-col>
									<!-- 继承的数据添加背景色-->
									<el-col :span="23" class="set_bc" v-if="prominentproblemdesc.updUserName == undefined || prominentproblemdesc.updUserName == ''">
										<el-col :span="17" v-if="prominentproblemdesc.recNo == '' || (updateFlg && prominentproblemdesc.recNo == updateDescRecNo)">
											<el-input class="desc_input_bc" type="textarea" autosize v-model="prominentproblemdesc.descContent"></el-input>
										</el-col>
										<el-col :span="17" class="desc_span_color" v-else>
											{{prominentproblemdesc.descContent}}
										</el-col>
										<el-col :span="4" class="un_col">
											<span class="un_span" v-if="prominentproblemdesc.updUserName != undefined && prominentproblemdesc.updUserName != '' && prominentproblemdesc.updDate != undefined && prominentproblemdesc.updDate != ''">
												{{ prominentproblemdesc.updUserName}} {{prominentproblemdesc.updDate}}
											</span>
										</el-col>
										<!-- 占位 -->
										<!-- <el-col :span="1" > -->
											<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
										<!-- </el-col> -->
										<!--新增和修改时按钮操作-->
										<el-col :span="1" v-if="prominentproblemdesc.recNo == '' || (updateFlg && prominentproblemdesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="saveDescHandle(prominentproblemdesc)"> 保存 </el-button>
										</el-col>
										<el-col :span="1" v-if="prominentproblemdesc.recNo == '' || (updateFlg && prominentproblemdesc.recNo == updateDescRecNo)">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="cancelDescHandle()"> 取消 </el-button>
										</el-col>
										<!--非新增和修改时按钮操作-->
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="updateDescHandle(prominentproblemdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
										</el-col>
										<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
											<el-button v-preventReClick type="text" size="small" class="desc_btn"
											@click="deleteDescHandle(prominentproblemdesc.recNo)"> × 删除</el-button>
										</el-col>
									</el-col>
								</el-col>
							</draggable>
							<el-col :span="24" style="margin-top:10px">
								<el-button type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
								style="background-color: #00428e;" @click="addProminentProblemDescItem(prominentProblemDesc.subjectNo)">追加处理情况</el-button>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.3 不一致项报告及监理联系单处理及跟踪情况</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.3.1 截止本周末共下发</span>
							<span class="summaryvalue2"> {{dataForm.contactTotalNum}} </span>
							<!-- <el-input class="selItemInput" v-model="dataForm.contactTotalNum"
								placeholder="请输入"></el-input> -->
								份监理联系单，已整改完成
								<span class="summaryvalue2"> {{dataForm.contactFinishNum}} </span>
							<!-- <el-input class="selItemInput" v-model="dataForm.contactFinishNum"
								placeholder="请输入"></el-input> -->
							份
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(contactItemRecord, index) in dataForm.contactItemRecordList" :key="index">
					<el-col class="set_sub">{{ contactItemRecord.projectNo }}</el-col>
					<el-col :span="24" style="margin-bottom: 10px;margin-top: 10px;">
						<el-table class="dataListTable" :data="contactItemRecord.itemRecordList" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader"
							:cell-style="{ padding: '0px' }">
							<el-table-column prop="noticeNo" label="监理联系单编号" min-width="200" align="left">
							</el-table-column>
							<el-table-column prop="primaryContent" label="主要内容" min-width="350" align="left"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="sendTime" label="下发日期" min-width="100" align="center">
							</el-table-column>
							<el-table-column prop="closeTime" label="封闭日期" min-width="100" align="center">
							</el-table-column>
							<el-table-column label="备 注" prop="remark" width="250" align="center" show-overflow-tooltip>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.3.2 截止本周末共下发</span>
							<span class="summaryvalue2"> {{dataForm.noticeTotalNum}} </span>
							<!-- <el-input class="selItemInput" v-model="dataForm.contactTotalNum"
								placeholder="请输入"></el-input> -->
								份监理通知单，已整改完成
								<span class="summaryvalue2"> {{dataForm.noticeFinishNum}} </span>
							<!-- <el-input class="selItemInput" v-model="dataForm.contactFinishNum"
								placeholder="请输入"></el-input> -->
							份
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(noticeItemRecord, index) in dataForm.noticeItemRecordList" :key="index">
					<el-col class="set_sub">{{ noticeItemRecord.projectNo }}</el-col>
					<el-col :span="24" style="margin-bottom: 10px;margin-top: 10px;">
						<el-table class="dataListTable" :data="noticeItemRecord.itemRecordList" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader"
							:cell-style="{ padding: '0px' }">
							<el-table-column prop="noticeNo" label="监理联系单编号" min-width="200" align="left">
							</el-table-column>
							<el-table-column prop="primaryContent" label="主要内容" min-width="350" align="left"
								show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="sendTime" label="下发日期" min-width="100" align="center">
							</el-table-column>
							<el-table-column prop="closeTime" label="封闭日期" min-width="100" align="center">
							</el-table-column>
							<el-table-column label="备 注" prop="remark" width="250" align="center" show-overflow-tooltip>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.3.2 截止本周末共下发</span>
							<span class="summaryvalue2"> {{dataForm.disAccordSendNum}} </span>
							<!-- <el-input class="selItemInput" v-model="dataForm.disAccordSendNum"
								placeholder="请输入"></el-input> -->
							份不一致报告。
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label-width="0">
							<span class="itemLabelspan">3.4 质量照片</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-for="(qualityPhotoRecord, idxs) in dataForm.qualityPhotoRecords">
					<el-col :span="24" class="set_sub">{{ qualityPhotoRecord.projectNo }}</el-col>
					<el-button v-preventReClick  type="primary" size="small" :disabled="updateFlg || addFlg || basicVisible" style="margin-bottom: 10px;background-color: #00428e;"	@click="handleQualityPhotoSelect(qualityPhotoRecord, idxs)">选择照片</el-button>
					<el-col :span="24">
						<div class="card-container">
							<div class="card-row" v-for="(row, rowIndex) in cardRows(qualityPhotoRecord.photoList)"
								:key="rowIndex">
								<el-row>
									<div class="card" v-for="(item, colIndex) in row" :key="item.recNo"
										@dragstart="dragStart('quality', idxs, rowIndex, colIndex)" @dragover.prevent
										@drop="drop('quality', idxs, rowIndex, colIndex)" @dragend="dragEnd()"
										draggable="true">
										<el-col :span="4">
											<el-card :body-style="{ padding: '0px' }"
												style="margin-bottom: 10px;margin-right: 5px;height: 285px;">
												<el-image :src="item.photoPathUri" fit="scale-down"
													class="image"></el-image>
												<div style="padding: 2px 10px;">
													<div class="t-column-ellipsis">
														<span>{{ item.photoContent }}</span>
													</div>
													<div class="bottom clearfix">
														<el-button type="text" class="button" :disabled="updateFlg || addFlg || basicVisible"
															@click="delImg(item.recNo)">删除</el-button>
														<el-button type="text" class="button" :disabled="updateFlg || addFlg || basicVisible"
															style="margin-right: 10px;"
															@click="updateImgContent(item.recNo, item.photoContent)">修改</el-button>
													</div>
												</div>
											</el-card>
										</el-col>
									</div>
								</el-row>
							</div>
						</div>
					</el-col>
				</el-row>
				<el-row v-if="!dataForm.qualityPhotoRecords || dataForm.qualityPhotoRecords.length === 0">
					<div>
						<span style="color: #e7b243;font-size: 14px;">暂无质量照片</span>
					</div>
				</el-row>
				<div ref="workfile"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">4. 文件审查情况
				</div>
				<el-row>
					<el-col :span="24">
						<el-table class="dataListTable" :data="dataForm.fileCheckRecords" header-align="center" border
							style="width: 100%;" header-cell-class-name="dataListTHeader"
							:cell-style="{ padding: '0px' }">
							<el-table-column type="index" label="序号" align="center" width="150">
							</el-table-column>
							<el-table-column prop="fileName" label="文件名称" align="center" min-width="250">
							</el-table-column>
							<el-table-column prop="examineStatusName" label="审查状态" align="center" min-width="150">
							</el-table-column>
							<el-table-column prop="examineSuggestion" label="审查意见" show-overflow-tooltip align="center"
								min-width="150">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<div ref="design"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">5. 设计修改及审图意见落实情况</div>
				<el-row>
					<draggable v-model="dataForm.designEditDesc" @start="onDragStart('050000')" @update="onDragEnd">
						<el-col :span="24" v-for="(designdesc, index) in dataForm.designEditDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non" v-if="designdesc.updUserName != undefined && designdesc.updUserName != ''">
								<el-col :span="17" v-if="designdesc.recNo == '' || (updateFlg && designdesc.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="designdesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{designdesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="designdesc.updUserName != undefined && designdesc.updUserName != '' && designdesc.updDate != undefined && designdesc.updDate != ''">
										{{ designdesc.updUserName}} {{designdesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="designdesc.recNo == '' || (updateFlg && designdesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(designdesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="designdesc.recNo == '' || (updateFlg && designdesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && designdesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(designdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && designdesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(designdesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
							<!-- 继承的数据添加背景色-->
							<el-col :span="23" class="set_bc" v-if="designdesc.updUserName == undefined || designdesc.updUserName == ''">
								<el-col :span="17" v-if="designdesc.recNo == '' || (updateFlg && designdesc.recNo == updateDescRecNo)">
									<el-input class="desc_input_bc" type="textarea" autosize v-model="designdesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span_color" v-else>
									{{designdesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="designdesc.updUserName != undefined && designdesc.updUserName != '' && designdesc.updDate != undefined && designdesc.updDate != ''">
										{{ designdesc.updUserName}} {{designdesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="designdesc.recNo == '' || (updateFlg && designdesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(designdesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="designdesc.recNo == '' || (updateFlg && designdesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(designdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(designdesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-top:10px">
						<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
						style="background-color: #00428e;" @click="addDesignEditDescItem()">追加设计修改及审图意见落实情况</el-button>
					</el-col>
				</el-row>
				<div ref="hse"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">6. HSE情况</div>
				<el-row>
					<draggable v-model="dataForm.hseInfoDesc" @start="onDragStart('060000')" @update="onDragEnd">
						<el-col :span="24" v-for="(hsedesc, index) in dataForm.hseInfoDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non" v-if="hsedesc.updUserName != undefined && hsedesc.updUserName != ''">
								<el-col :span="17" v-if="hsedesc.recNo == '' || (updateFlg && hsedesc.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="hsedesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{hsedesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="hsedesc.updUserName != undefined && hsedesc.updUserName != '' && hsedesc.updDate != undefined && hsedesc.updDate != ''">
										{{ hsedesc.updUserName}} {{hsedesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="hsedesc.recNo == '' || (updateFlg && hsedesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(hsedesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="hsedesc.recNo == '' || (updateFlg && hsedesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && hsedesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(hsedesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && hsedesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(hsedesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
							<!-- 继承的数据添加背景色-->
							<el-col :span="23" class="set_bc" v-if="hsedesc.updUserName == undefined || hsedesc.updUserName == ''">
								<el-col :span="17" v-if="hsedesc.recNo == '' || (updateFlg && hsedesc.recNo == updateDescRecNo)">
									<el-input class="desc_input_bc" type="textarea" autosize v-model="hsedesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span_color" v-else>
									{{hsedesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="hsedesc.updUserName != undefined && hsedesc.updUserName != '' && hsedesc.updDate != undefined && hsedesc.updDate != ''">
										{{ hsedesc.updUserName}} {{hsedesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="hsedesc.recNo == '' || (updateFlg && hsedesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(hsedesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="hsedesc.recNo == '' || (updateFlg && hsedesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(hsedesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(hsedesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-top:10px">
						<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
						style="background-color: #00428e;" @click="addHseInfoDescItem()">追加HSE情况</el-button>
					</el-col>
				</el-row>
				<div ref="meet"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">7. 会议情况
				</div>
				<el-row v-for="(desc, idx) in meetDescList">
					<el-col>
						<el-row>
							<el-col :span="20" style="margin-bottom: 8px;">
								{{ meetDescList[idx].descContent }}
							</el-col>
							<el-col :span="4">
								<el-button v-preventReClick type="text" class="button"
									@click="delMeetDesc(idx)">删除</el-button>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<div class="card-container">
									<div class="card-row" v-for="(row, rowIndex) in cardRows(meetDescList[idx].photoRecords)"
										:key="rowIndex">
										<el-row>
											<div class="card" v-for="(item, colIndex) in row" :key="item.recNo"
												@dragstart="dragStart('meeting', idx, rowIndex, colIndex)" @dragover.prevent
												@drop="drop('meeting', idx, rowIndex, colIndex)" @dragend="dragEnd()"
												draggable="true">
												<el-col :span="4">
													<el-card :body-style="{ padding: '0px' }"
														style="margin-bottom: 10px;margin-right: 5px;height: 285px;">
														<el-image :src="item.photoFilePathUrl" fit="scale-down"
															class="image"></el-image>
														<div style="padding: 2px 10px;">
															<div class="t-column-ellipsis">
																<span>{{ item.photoContent }}</span>
															</div>
															<!-- <div class="bottom clearfix">
																<el-button type="text" class="button"
																	@click="delImg('meeting', idx, rowIndex, colIndex)">删除</el-button>
															</div> -->
														</div>
													</el-card>
												</el-col>
											</div>
										</el-row>
									</div>
								</div>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
				<div ref="other"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">8. 其他需要说明的问题</div>
				<el-row>
					<draggable v-model="dataForm.otherDesc" @start="onDragStart('080000')" @update="onDragEnd">
						<el-col :span="24" v-for="(othdesc, index) in dataForm.otherDesc" :key="index">
							<el-col :span="1">
								<i class="el-icon-position"></i>
							</el-col>
							<el-col :span="23" class="set_non" v-if="othdesc.updUserName != undefined && othdesc.updUserName != ''">
								<el-col :span="17" v-if="othdesc.recNo == '' || (updateFlg && othdesc.recNo == updateDescRecNo)">
									<el-input class="desc_input1" type="textarea" autosize v-model="othdesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span" v-else>
									{{othdesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="othdesc.updUserName != undefined && othdesc.updUserName != '' && othdesc.updDate != undefined && othdesc.updDate != ''">
										{{ othdesc.updUserName}} {{othdesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="othdesc.recNo == '' || (updateFlg && othdesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(othdesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="othdesc.recNo == '' || (updateFlg && othdesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && othdesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(othdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible && othdesc.showButton == 1">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(othdesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
							<!-- 继承的数据添加背景色-->
							<el-col :span="23" class="set_bc" v-if="othdesc.updUserName == undefined || othdesc.updUserName == ''">
								<el-col :span="17" v-if="othdesc.recNo == '' || (updateFlg && othdesc.recNo == updateDescRecNo)">
									<el-input class="desc_input_bc" type="textarea" autosize v-model="othdesc.descContent"></el-input>
								</el-col>
								<el-col :span="17" class="desc_span_color" v-else>
									{{othdesc.descContent}}
								</el-col>
								<el-col :span="4" class="un_col">
									<span class="un_span" v-if="othdesc.updUserName != undefined && othdesc.updUserName != '' && othdesc.updDate != undefined && othdesc.updDate != ''">
										{{ othdesc.updUserName}} {{othdesc.updDate}}
									</span>
								</el-col>
								<!-- 占位 -->
								<!-- <el-col :span="1" > -->
									<el-button v-preventReClick type="text" size="small" class="desc_zw_bc"> . </el-button>
								<!-- </el-col> -->
								<!--新增和修改时按钮操作-->
								<el-col :span="1" v-if="othdesc.recNo == '' || (updateFlg && othdesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="saveDescHandle(othdesc)"> 保存 </el-button>
								</el-col>
								<el-col :span="1" v-if="othdesc.recNo == '' || (updateFlg && othdesc.recNo == updateDescRecNo)">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="cancelDescHandle()"> 取消 </el-button>
								</el-col>
								<!--非新增和修改时按钮操作-->
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="updateDescHandle(othdesc.recNo)"> <i class="el-icon-edit"></i>修改</el-button>
								</el-col>
								<el-col :span="1" v-if="!addFlg && !updateFlg && !basicVisible">
									<el-button v-preventReClick type="text" size="small" class="desc_btn"
									@click="deleteDescHandle(othdesc.recNo)"> × 删除</el-button>
								</el-col>
							</el-col>
						</el-col>
					</draggable>
					<el-col :span="24" style="margin-top:10px">
						<el-button v-preventReClick type="primary" size="mini" icon="el-icon-plus" :disabled="updateFlg || addFlg || basicVisible"
						style="background-color: #00428e;" @click="addOtherDescItem()">追加其他需要说明的问题</el-button>
					</el-col>
				</el-row>
				<div ref="enclosure"></div>
			</el-card>
			<el-card style="margin-top: 20px; height: 100%;">
				<div class="elCardTitle">9. 随周报发送的附件</div>
				<el-row>
					<el-col :span="24"><el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-upload"
							@click="editHandle()">添加附件</el-button></el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-table ref="singleTable" class="dataListTable" :data="enclosureList" header-align="center"
							style="width: 100%;" empty-text="无附件" header-cell-class-name="dataListTHeader" border
							:cell-style="{ padding: '0px' }" row-key="recNo">
							<el-table-column type="index" label="序号" align="center" width="60">
							</el-table-column>
							<el-table-column prop="fileName" label="文件名称" align="left" min-width="250">
							</el-table-column>
							<el-table-column prop="remark" label="备注" align="center" min-width="150">
							</el-table-column>
							<el-table-column label="操作" align="center" width="300">
								<template slot-scope="scope">
									<el-button v-preventReClick type="danger" size="small"
										@click="downloadHandle(scope.row.filePathUri, scope.row.fileName)"
										v-if="scope.row.filePathUri">下载</el-button>
									<el-button v-preventReClick type="warning" size="small"
										@click="editHandle(scope.row.recNo)">修改</el-button>
									<el-button v-preventReClick type="danger" size="small"
										@click="deleteHandle(scope.row.recNo)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
			</el-card>
			<!-- <el-card style="margin-top: 20px; height: 100%; padding-top: 30px;">
				<el-row>
					<el-col :span="7" class="col-set_border1">
						<el-form-item label="报告人" label-width="120px" class="item">
							<el-input class="selItemInput" v-model="dataForm.reportMan" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="7" class="col-set_border2">
						<el-form-item label="日期" label-width="120px" class="item">
							<el-date-picker class="selItemInput" v-model="dataForm.reportTime" type="date"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="7" class="col-set_border3">
						<el-form-item label="项目总监" label-width="120px" class="item">
							<el-input class="selItemInput" v-model="dataForm.projectDirector"
								placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="7" class="col-set_border4">
						<el-form-item label="日期" label-width="120px" class="item">
							<el-date-picker class="selItemInput" v-model="dataForm.projectDirectorTime" type="date"
								value-format="yyyy-MM-dd" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card> -->
		</el-form>
		<!-- <el-row class="formBtnGroup" style="margin-top: 40px;">
			<div class="set_bg">
				<el-button v-preventReClick class="btn1" type="primary" size="small"
					@click="dataFormSubmit()">保存</el-button>
				<el-button v-preventReClick class="rebtn1" size="small" plain @click="goBack()">取消</el-button>
			</div>
		</el-row> -->
		<el-row>
			<div class="set_btn" v-if="menuId !== 'basic'" @click="jump_to_basic" style="bottom: 187px;"></div>
			<div class="set_btn_border" v-if="menuId === 'basic'" @click="jump_to_basic" style="bottom: 187px;"></div>
			<div class="set_menu" style="bottom: 185px;" @click="jump_to_basic">基本信息</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'overview'" @click="jump_to_overview" style="bottom: 167px;"></div>
			<div class="set_btn_border" v-if="menuId === 'overview'" @click="jump_to_overview" style="bottom: 167px;">
			</div>
			<div class="set_menu" style="bottom: 165px;" @click="jump_to_overview">1.概述</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'progress'" @click="jump_to_progress" style="bottom: 147px;"></div>
			<div class="set_btn_border" v-if="menuId === 'progress'" @click="jump_to_progress" style="bottom: 147px;">
			</div>
			<div class="set_menu" style="bottom: 145px;" @click="jump_to_progress">2.施工进度情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'quality'" @click="jump_to_quality" style="bottom: 127px;"></div>
			<div class="set_btn_border" v-if="menuId === 'quality'" @click="jump_to_quality" style="bottom: 127px;">
			</div>
			<div class="set_menu" style="bottom: 125px;" @click="jump_to_quality">3.质量情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'workfile'" @click="jump_to_workFile" style="bottom: 107px;"></div>
			<div class="set_btn_border" v-if="menuId === 'workfile'" @click="jump_to_workFile" style="bottom: 107px;">
			</div>
			<div class="set_menu" style="bottom: 105px;" @click="jump_to_workFile">4.文件审查情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'design'" @click="jump_to_design" style="bottom: 87px;"></div>
			<div class="set_btn_border" v-if="menuId === 'design'" @click="jump_to_design" style="bottom: 87px;"></div>
			<div class="set_menu" style="bottom: 85px;" @click="jump_to_design">5.设计修改及审图意见落实情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'hse'" @click="jump_to_hse" style="bottom: 67px;"></div>
			<div class="set_btn_border" v-if="menuId === 'hse'" @click="jump_to_hse" style="bottom: 67px;"></div>
			<div class="set_menu" style="bottom: 65px;" @click="jump_to_hse">6.HSE情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'meet'" @click="jump_to_meet" style="bottom: 47px;"></div>
			<div class="set_btn_border" v-if="menuId === 'meet'" @click="jump_to_meet" style="bottom: 47px;"></div>
			<div class="set_menu" style="bottom: 45px;" @click="jump_to_meet">7.会议情况</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'other'" @click="jump_to_other" style="bottom: 27px;"></div>
			<div class="set_btn_border" v-if="menuId === 'other'" @click="jump_to_other" style="bottom: 27px;"></div>
			<div class="set_menu" style="bottom: 25px;" @click="jump_to_other">8.其他需要说明的问题</div>
		</el-row>
		<el-row>
			<div class="set_btn" v-if="menuId !== 'enclosure'" @click="jump_to_enclosure" style="bottom: 7px;"></div>
			<div class="set_btn_border" v-if="menuId === 'enclosure'" @click="jump_to_enclosure" style="bottom: 7px;">
			</div>
			<div class="set_menu" style="bottom: 5px;" @click="jump_to_enclosure">9.随周报发送的附件</div>
		</el-row>

		<el-dialog title="添加照片" :visible.sync="dialogVisible" width="30%">
			<el-form>
				<el-row>
					<el-col :span="24">
						<el-form-item label="工程编号">
							<el-select id="photoPojectNo" v-model="photoPojectNo" placeholder="请选择">
								<el-option v-for="item in subjectList" :key="item.displayValue" :label="item.projectNo"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="描述">
							<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 5 }" v-model="photoContent"
								placeholder="请填写"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="照片">
							<div class="addImgBox" v-for="(item, index) in photoList">
								<el-image style="width: 100%; height: 100%" :src="item.photoPathUri"
									fit="cover"></el-image>
								<div class="delBox">
									<i class="el-icon-delete" @click="delPhotoImg(index)"></i>
								</div>
							</div>
							<el-upload class="addImgBox" ref="uploadTempImg" :action="action"
								:http-request="uploadTempImg" accept="image/jpeg,image/png,image/jpg"
								v-if="!photoList || photoList.length === 0">
								<el-image style="width: 100%; height: 100%" :src="addImg" fit="cover">
								</el-image>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick @click="dialogVisible = false">取 消</el-button>
				<el-button v-preventReClick type="primary" @click="savePhotoList">确 定</el-button>
			</span>

		</el-dialog>
		<el-dialog title="添加照片" :visible.sync="meetPhotoDialogVisible" width="30%">
			<el-form>
				<el-row>
					<el-col :span="24">
						<el-form-item label="描述">
							<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 5 }" v-model="meetPhotoContent"
								placeholder="请填写"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="照片">
							<div class="addImgBox" v-for="(item, index) in meetPhotoList">
								<el-image style="width: 100%; height: 100%" :src="item.photoFilePathUrl"
									fit="cover"></el-image>
								<div class="delBox">
									<i class="el-icon-delete" @click="delMeetDialogPhoto(index)"></i>
								</div>
							</div>
							<el-upload class="addImgBox" ref="uploadMeetImg" :action="action"
								:http-request="uploadMeetImg" accept="image/jpeg,image/png,image/jpg"
								v-if="!meetPhotoList || meetPhotoList.length === 0">
								<el-image style="width: 100%; height: 100%" :src="addImg" fit="cover">
								</el-image>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick @click="meetPhotoDialogVisible = false">取 消</el-button>
				<el-button v-preventReClick type="primary" @click="saveMeetPhotoList">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 质量进度照片内容修改 -->
		<el-dialog title="修改描述" :visible.sync="updateImgContentVisable" width="30%" :close-on-click-modal="false">
			<el-form>
				<el-row>
					<el-col :span="24">
						<el-form-item label="">
							<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }"
								v-model="updatePhotoContent" placeholder="请填写" style="width:420px"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick class="btn" type="primary" size="small"
					@click="saveImgContent">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="updateImgContentVisable = false">取
					消</el-button>
			</span>
		</el-dialog>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getEnclosureList"></add-or-update>
		<quality-progress-photo-select v-if="qpPhotoSelectVisible" ref="qpPhotoSelect"
			@selectItems="margeSelectItems"></quality-progress-photo-select>
	</div>
</template>
<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js";
import AddOrUpdate from './enclosure-add-or-update'
import QualityProgressPhotoSelect from './quality-progress-photo-select'
import Sortable from "sortablejs";
import draggable from 'vuedraggable';

export default {
	data() {
		return {
			basicVisible: false,
			addOrUpdateVisible: false,
			qpPhotoSelectVisible: false,
			photoFlag: 0,
			dialogVisible: false,
			meetPhotoDialogVisible: false,
			meetPhotoListIndex: 0,
			addImg: addImg,
			recNo: '',
			beginTime: '',
			action: '',
			endTime: '',
			photoList: [],
			meetDescList: [],
			meetPhotoList: [],
			enclosureList: [],
			projectDesc: [],
			majorList: [],
			subjectList: {},
			photoPojectNo: '',
			subjectNo: '',
			photoContent: '',
			meetPhotoContent: '',
			workNo: $common.getItem("workNo"),
			dataRule: {},
			dataForm: {
				projectInfo: {
					projectName: ''
				}
			},
			photoViewCol: 6,
			draggedItem: {},
			selectPhotoType: '',
			recNos: [],
			updateImgContentVisable: false,
			photoType: '',
			photoRecNo: '',
			updatePhotoContent: '',
			contentIdx: '',
			menuId: 'basic',
			addFlg: false,
			updateFlg: false,
			updateDescRecNo: '',
			qpPhotoIndex: 0,
			updateDataType: '',
			updateIndex: '',
			updateIndex1: ''
		};
	},
	components: {
		AddOrUpdate,
		QualityProgressPhotoSelect,
		draggable
	},
	mounted() {
		this.recNo = this.$route.query.recNo;
		this.getSubjectDropDown();
		this.getMajorList()
		this.dragSort();
		this.getEnclosureList();
		this.getWeekInfo();
	},
	methods: {
		getCurrentDate() {
			//获取当前时间并打印
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			if(mm< 10) {
				mm = '0' + mm;
			}
			return yy + '.' + mm + '.' + dd;
		},
		onDragStart(dataType, index, index1) {
			this.updateDataType = dataType;
			this.updateIndex = index;
			this.updateIndex1 = index1;
		},
		onDragEnd(event) {
			event.preventDefault();
			// 拖拽结束后的处理逻辑
			let records = [];
			if (this.updateDataType == '010100') {
				// 1.1 重要事项描述
				records = this.dataForm.projectDesc
			} else if (this.updateDataType == '020300') {
				// 2.3 详细进度路况
				records = this.dataForm.constructionProgress[this.updateIndex].conProgressList[this.updateIndex1].descProgressList
			} else if (this.updateDataType == '030101') {
				// 3.1.1 停止点检查（报验）情况 总体描述
				records = this.dataForm.stopPointCheckDesc
			} else if (this.updateDataType == '030102') {
				// 3.1.1 停止点检查（报验）情况 主要质量问题跟踪处理情况
				records = this.dataForm.qualityProblemDescList[this.updateIndex].reportDescInfos;
			} else if (this.updateDataType == '030201') {
				// 3.2.1 巡检检查情况 总体描述
				records = this.dataForm.patrolCheckDesc
			} else if (this.updateDataType == '030202') {
				// 3.2.1 巡检检查情况 突出问题跟踪处理情况
				records = this.dataForm.prominentProblemDescList[this.updateIndex].reportDescInfos;
			} else if (this.updateDataType == '050000') {
				// 5.设计修改及审图意见落实情况
				records = this.dataForm.designEditDesc
			} else if (this.updateDataType == '060000') {
				// 6.HSE情况
				records = this.dataForm.hseInfoDesc
			} else if (this.updateDataType == '080000') {
				// 8. 其他需要说明的问题
				records = this.dataForm.otherDesc
			} 
			this.$http({
				url: this.$store.state.httpUrl + "/business/weeklyreportdescinfo/sort",
				method: "post",
				data: this.$http.adornData({
					dataType: this.updateDataType,
					weeklyRecNo: this.recNo,
					records : records
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.updateDataType = ''
							this.getWeekInfo();
						}
					});
				}
			});
		},
		dragSort() {
			const el = this.$refs.singleTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
			this.sortable = Sortable.create(el, {
				onEnd: e => {	//onEnd是结束拖拽时触发，onUpdate是列表内元素顺序更新的时候触发，更多请看文末配置项
					//e.oldIndex为拖动一行原来的位置，e.newIndex为拖动后新的位置
					const targetRow = this.enclosureList.splice(e.oldIndex, 1)[0];
					this.enclosureList.splice(e.newIndex, 0, targetRow);
					let recNo = this.enclosureList[e.newIndex].recNo;//拖动行的id
					// 后端发送请求排序
					this.$http({
						url: this.$store.state.httpUrl + "/business/weekreportenclosure/sort",
						method: "POST",
						data: this.$http.adornData({
							enclosureList: this.enclosureList,
							workNo: $common.getItem("workNo"),
							detailRecNo: this.recNo
						}),
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getEnclosureList()
								}
							})
						}
					});
				}
			})
		},
		/* 照片拖拽 begin */
		cardRows(photoList) {
			const rows = [];
			if (photoList != null) {
				for (let i = 0; i < photoList.length; i += this.photoViewCol) {
					rows.push(photoList.slice(i, i + this.photoViewCol));
				}
			}
			return rows;
		},
		dragStart(type, idxs, rowIndex, colIndex) {
			this.draggedItem = { type: type, idxs: idxs, row: rowIndex, col: colIndex };
		},
		drop(type, idxs, rowIndex, colIndex) {
			if (type !== this.draggedItem.type || idxs !== this.draggedItem.idxs) {
				return
			}
			const x = this.draggedItem.row * this.photoViewCol + this.draggedItem.col
			const y = rowIndex * this.photoViewCol + colIndex
			let photoList = []
			if (type === 'progress') {
				photoList = this.dataForm.progressPhotoRecords[idxs].photoList
			} else if (type === 'quality') {
				photoList = this.dataForm.qualityPhotoRecords[idxs].photoList
			} else if (type === 'meeting') {
				photoList = this.meetDescList[idxs].photoRecords
			}
			if (x == y) {
				return
			} else if (x < y) {
				const item = photoList.splice(x, 1)[0];
				photoList.splice(y, 0, item);
			} else {
				const item = photoList.splice(x, 1)[0];
				photoList.splice(y, 0, item);
			}

			if(photoList != undefined && photoList.length > 1) {
				// 直接保存至db
				let url = "";
				if(type === 'progress' || type === 'quality') {
					url = "/business/weeklyreportphoto/sort"
					// 质量照片和进度照片	
				} else if(type === 'meeting') {
					// 会议照片
					url = "/business/weeklyreportdescphoto/sort"
				}
				this.$http({
					url: this.$store.state.httpUrl + url,
					method: "POST",
					data: this.$http.adornData({
						records: photoList,
					}),
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getWeekInfo();
							}
						})
					}
				});
			}

		},
		dragEnd() {
			this.draggedItem = {};
		},
		updateBasicHandle() {
			this.basicVisible = true;
		},
		cancelSaveBasicHandle() {
			this.getWeekInfo()
			// this.basicVisible = false;
		},
		saveBasicHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/weeklyreportinfo/updateWeeklyBasic",
				method: 'post',
				data: this.$http.adornData({
					recNo: this.weeklyRecNo,
					weeklyRecNo: this.dataForm.weeklyRecNo,
					weeklyName: this.dataForm.weeklyName,
					projectNum: this.dataForm.projectNum,
					projectSubjectNo: this.dataForm.projectSubjectNo,
					reportMan: this.dataForm.reportMan,
					reportTime: this.dataForm.reportTime,
					projectDirector: this.dataForm.projectDirector,
					projectDirectorTime: this.dataForm.projectDirectorTime,
					qualitySituation: this.dataForm.qualitySituation,
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.basicVisible = false;
							this.getWeekInfo()
						}
					})
				}
			})
		},
		saveDescHandle(reportDesc) {
			let _url = "/business/weeklyreportdescinfo/save";
			if (reportDesc.recNo) {
				reportDesc.updUserName = $common.getItem("userName")
				_url = "/business/weeklyreportdescinfo/update";
			}
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(reportDesc)
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.getWeekInfo();
						}
					});
				}
			});
		},
		updateDescHandle(descRecNo) {
			this.updateFlg = true,
			this.updateDescRecNo = descRecNo
		},
		cancelDescHandle() {
			this.getWeekInfo();
		},
		deleteDescHandle(descRecNo) {
			// 直接db修改
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportdescinfo/delete/" + descRecNo,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getWeekInfo();
							}
						})
					} 
				});
			})
		},
		// delImg(type, idxs, rowIndex, colIndex) {
		// 	const index = rowIndex * this.photoViewCol + colIndex
		// 	let photoList = []
		// 	if (type === 'progress') {
		// 		photoList = this.dataForm.progressPhotoRecords[idxs].photoList
		// 	} else if (type === 'quality') {
		// 		photoList = this.dataForm.qualityPhotoRecords[idxs].photoList
		// 	} else if (type === 'meeting') {
		// 		photoList = this.meetDescList[idxs].photoRecords
		// 	}
		// 	photoList.splice(index, 1);
		// },
		delImg(recNo) { 
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportphoto/delete/" + recNo,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getWeekInfo();
							}
						})
					} 
				});
			})
		},
		updateImgContent(recNo, photoContent) {
			this.updateImgContentVisable = true;
			this.photoRecNo = recNo;
			this.updatePhotoContent = photoContent;
		},
		saveImgContent() {
			// 直接更新到db
			let _url = "/business/weeklyreportphoto/updatePhotoContent";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					recNo: this.photoRecNo,
					photoRemark: this.updatePhotoContent
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.updateImgContentVisable = false
							this.getWeekInfo()
						}
					})
				}
			});
			// let idxs = this.contentIdx
			// let photoList = []
			// if (this.photoType === 'progress') {
			// 	photoList = this.dataForm.progressPhotoRecords[idxs].photoList
			// } else if (this.photoType === 'quality') {
			// 	photoList = this.dataForm.qualityPhotoRecords[idxs].photoList
			// }
			// for (var i = 0; i < photoList.length; i++) {
			// 	if (photoList[i].recNo === this.photoRecNo) {
			// 		photoList[i].photoContent = this.updatePhotoContent
			// 		break
			// 	}
			// }
			// this.updateImgContentVisable = false;
		},
		/* 照片拖拽 end */
		downloadHandle(url, filename) {
			filename = this.getSuffix(url, filename)
			this.getBlob(url, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		editHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.recNo)
			})
		},
		handleProgressPhotoSelect(progressPhotoRecord, index) {
			this.selectPhotoType = '2'
			this.qpPhotoSelectVisible = true
			let photoList = progressPhotoRecord.photoList;
			this.qpPhotoIndex = index
			// 获取现场照片来源ids
			let recNos =''
			if(photoList != undefined && photoList.length > 0) {
				for(var i = 0; i < photoList.length; i++) {
					recNos = recNos + photoList[i].sourceId;
					if(i < photoList.length - 1) {
						recNos = recNos + ','
					}
				}	
			}
			this.$nextTick(() => {
				this.$refs.qpPhotoSelect.init("2", this.dataForm.beginTime, this.dataForm.endTime, recNos, progressPhotoRecord.subjectNo)
			})
		},
		handleQualityPhotoSelect(qualityPhotoRecord, index) {
			this.selectPhotoType = '1'
			this.qpPhotoSelectVisible = true
			let photoList = qualityPhotoRecord.photoList;
			this.qpPhotoIndex = index
			// 获取现场照片来源ids
			let recNos =''
			if(photoList != undefined && photoList.length > 0) {
				for(var i = 0; i < photoList.length; i++) {
					recNos = recNos + photoList[i].sourceId;
					if(i < photoList.length - 1) {
						recNos = recNos + ','
					}
				}	
			}
			this.$nextTick(() => {
				this.$refs.qpPhotoSelect.init("1", this.dataForm.beginTime, this.dataForm.endTime, recNos, qualityPhotoRecord.subjectNo)
			})
		},
		margeSelectItems(items) {
			let records = []
			let maxSort = 0;
			let photoList = []
			if (this.selectPhotoType === '2') {
				photoList = this.dataForm.progressPhotoRecords[this.qpPhotoIndex].photoList
			} else if (this.selectPhotoType === '1') {
				photoList = this.dataForm.qualityPhotoRecords[this.qpPhotoIndex].photoList

			}
			if(photoList != undefined && photoList.length > 0) {
				for(var i = 0; i < photoList.length; i++) {
					if(photoList[i].sort > maxSort) {
						maxSort = photoList[i].sort
					}
				}
			}
			if(items != undefined && items.length > 0) {
				for(var i = 0; i < items.length; i++) {
					let progressPhotot = {
						detailRecNo: this.dataForm.weeklyDetailRecNo,
						photoKind: this.selectPhotoType,
						subjectNo: items[i].subjectNo,
						sort: maxSort + 1 + i,
						photoPath: items[i].photoFilePath,
						thumbnailPath: items[i].thumbnailPath,
						sourceId: items[i].recNo,
						photoRemark:  items[i].photoContent,
						major: items[i].majorCode,
						photoPathUri: items[i].photoPathUri
					}
					records.push(progressPhotot)
				}
			}
			this.saveBatchWeeklyQPPhoto(records);

			// let noSubject = true;
			// 循环处理选择的图片
			// for (var selectItem of items) {
			// 	for (var record of records) {
			// 		// 相同的标的物
			// 		if (record.subjectNo == selectItem.subjectNo) {
			// 			let exist = false;
			// 			for (var photo of record.photoList) {
			// 				if (photo.photoPathUri == selectItem.photoPathUri) {
			// 					exist = true
			// 					break
			// 				}
			// 			}
			// 			if (!exist) {
			// 				record.photoList.push(selectItem)
			// 				break
			// 			}
			// 		}
			// 	}
			// }
		},
		saveBatchWeeklyQPPhoto(records) {
			let _url = "/business/weeklyreportphoto/saveBatch";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					records: records
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.qpPhotoSelectVisible = false;
							this.getWeekInfo();
						}
					});
				}
			});
		},
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weekreportenclosure/delete",
					method: "POST",
					data: this.$http.adornData([id], false),
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getEnclosureList()
							}
						})
					}
				});
			})
		},
		getEnclosureList() {
			this.$http({
				url: this.$store.state.httpUrl + `/business/weekreportenclosure/listByWeeklyId`,
				method: 'get',
				params: {
					workNo: $common.getItem("workNo"),
					detailRecNo: this.recNo
				}
			}).then(({ data }) => {
				if (data.resultCode === 200) {
					// this.enclosureList = data.body
					// 附件列表，重新设置附件名称
					let enclosureFiles = []
					if (data.body != undefined && data.body.length > 0) {
						for (var i = 0; i < data.body.length; i++) {
							data.body[i].fileName = '附件' + (i + 1) + "：" + data.body[i].fileName
							enclosureFiles.push(data.body[i]);
						}
					}
					this.enclosureList = enclosureFiles;
				}
			})
		},
		delPhotoImg(index) {
			this.photoList.splice(index, 1);
		},
		delMeetDialogPhoto(index) {
			this.meetPhotoList.splice(index, 1);
		},
		addPhoto(flag) {
			this.photoList = [];
			this.photoPojectNo = '';
			this.photoContent = '';
			this.dialogVisible = true;
			this.photoFlag = flag;
		},
		addMeetingDescPhoto(idx) {
			this.meetPhotoListIndex = idx
			this.meetPhotoList = [];
			this.meetPhotoContent = '';
			this.meetPhotoDialogVisible = true;
		},
		uploadTempImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadTempImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					this.photoList.push({
						photoFilePath: res.body.fileSavePath,
						photoPathUri: res.body.fileUri,
						thumbnailPath: res.body.thumbnailPath
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		uploadMeetImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadMeetImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					this.meetPhotoList.push({
						photoFilePath: res.body.fileSavePath,
						photoFilePathUrl: res.body.fileUri,
						thumbnailPath: res.body.thumbnailPath
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
				}
			});
		},
		getProjectCode(projectNo) {
			for (var item in this.subjectList) {
				if (this.subjectList[item].displayValue == projectNo) {
					return this.subjectList[item].projectNo
				}
			}
		},
		savePhotoList() {
			if (!this.photoPojectNo) {
				this.$message.error("请选择工程编号");
				return;
			}
			if (!this.photoContent) {
				this.$message.error('请填写照片描述');
				return;
			}
			if (!this.photoList || this.photoList.length === 0) {
				this.$message.error('请选择上传照片');
				return;
			}

			if (this.photoFlag === 1) {
				let flag = false;
				if (this.dataForm.progressPhotoRecords && this.dataForm.progressPhotoRecords.length > 0) {
					for (var i = 0; i < this.dataForm.progressPhotoRecords.length; i++) {
						if (this.dataForm.progressPhotoRecords[i].subjectNo == this.photoPojectNo) {
							flag = true;
							let item = {
								photoContent: this.photoContent,
								photoFilePath: this.photoList[0].photoFilePath,
								photoPathUri: this.photoList[0].photoPathUri,
								subjectNo: this.photoPojectNo
							};
							this.dataForm.progressPhotoRecords[i].photoList.push(item);
							break;
						}
					}
				}

				if (!flag) {
					let photoList = [];
					let progressPhotoRecord = {
						photoContent: this.photoContent,
						photoFilePath: this.photoList[0].photoFilePath,
						photoPathUri: this.photoList[0].photoPathUri,
						subjectNo: this.photoPojectNo
					}
					photoList.push(progressPhotoRecord);
					let item = {
						projectNo: this.getProjectCode(this.photoPojectNo),
						subjectNo: this.photoPojectNo,
						photoList: photoList
					};
					this.dataForm.progressPhotoRecords.push(item);
				}
			} else {
				let flag = false;
				if (this.dataForm.qualityPhotoRecords && this.dataForm.qualityPhotoRecords.length > 0) {
					for (var i = 0; i < this.dataForm.qualityPhotoRecords.length; i++) {
						if (this.dataForm.qualityPhotoRecords[i].subjectNo == this.photoPojectNo) {
							flag = true;
							let item = {
								photoContent: this.photoContent,
								photoFilePath: this.photoList[0].photoFilePath,
								photoPathUri: this.photoList[0].photoPathUri,
								subjectNo: this.photoPojectNo
							};
							this.dataForm.qualityPhotoRecords[i].photoList.push(item);
							break;
						}
					}
				}

				if (!flag) {
					let photoList = [];
					let qualityPhotoRecord = {
						photoContent: this.photoContent,
						photoFilePath: this.photoList[0].photoFilePath,
						photoPathUri: this.photoList[0].photoPathUri,
						subjectNo: this.photoPojectNo
					}
					photoList.push(qualityPhotoRecord);
					let item = {
						projectNo: this.getProjectCode(this.photoPojectNo),
						subjectNo: this.photoPojectNo,
						photoList: qualityPhotoDtoList
					};
					this.dataForm.qualityPhotoRecords.push(item);
				}
			}
			this.dialogVisible = false;
		},
		saveMeetPhotoList() {
			if (!this.meetPhotoContent) {
				this.$message.error('请填写照片描述');
				return;
			}
			if (!this.meetPhotoList || this.meetPhotoList.length === 0) {
				this.$message.error('请选择上传照片');
				return;
			}
			let idx = this.meetPhotoListIndex

			if (this.meetDescList[idx].photoRecords == null) {
				this.meetDescList[idx].photoRecords = []
			}
			let item = {
				photoContent: this.meetPhotoContent,
				photoFilePath: this.meetPhotoList[0].photoFilePath,
				photoFilePathUrl: this.meetPhotoList[0].photoFilePathUrl,
			};
			this.meetDescList[idx].photoRecords.push(item);

			this.meetPhotoDialogVisible = false;
		},
		addNoticeItem() {
			let item = {
				noticeNo: '',
				primaryContent: '',
				sendTime: '',
				closeTime: '',
				remark: ''
			};
			this.dataForm.noticeItemRecords.push(item);
		},
		deleteNoticeItem(index) {
			this.dataForm.noticeItemRecords.splice(index, 1);
		},
		addSectionalProgress() {
			let item = {
				projectNo: '',
				totalNum: 0,
				upNum: 0,
				checkNum: 0,
				paintingNum: 0,
				upShipNum: 0
			};
			this.dataForm.sectionalItemRecords.push(item);
		},
		deleteSectionalItem(index) {
			this.dataForm.sectionalItemRecords.splice(index, 1);
		},
		addMooringItem() {
			let item = {
				projectNo: '',
				testItemNum: 0,
				testFinishItemNum: 0,
				shipFinishItemNum: 0,
				turbineFinishItemNum: 0,
				electricalFinishItemNum: 0
			};
			this.dataForm.mooringTestItemRecords.push(item);
		},
		deleteMooringItem(index) {
			this.dataForm.mooringTestItemRecords.splice(index, 1);
		},
		addProjectDescItem() {
			// 计算最大排序值
			// var sort = 0;
			// if(this.dataForm.mainEventDescList != undefined && this.dataForm.mainEventDescList.length > 0) {
			// 	for(var i = 0 ; i < this.dataForm.mainEventDescList.length; i++ ) {
			// 		if(sort < this.dataForm.mainEventDescList[i].sort) {
			// 			sort = this.dataForm.mainEventDescList[i].sort
			// 		}
			// 	}
			// }
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				descContent: '',
				dataType: '010100',
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			this.dataForm.projectDesc.push(item);
			this.addFlg = true;
		},
		deleteProjectDescItem(index) {
			this.dataForm.projectDesc.splice(index, 1);
		},
		addStopPointCheckDescItem() {
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				descContent: '',
				dataType: '030101',
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			this.dataForm.stopPointCheckDesc.push(item);
			this.addFlg = true;
		},
		deleteStopPointCheckDescItem(index) {
			this.dataForm.stopPointCheckDesc.splice(index, 1);
		},
		getDescList(descList, subjectNo) {
			if (descList) {
				for (let i = 0; i < descList.length; i++) {
					if (descList[i].subjectNo == subjectNo) {
						return descList[i].reportDescInfos;
					}
				}
			} else {
				return [];
			}
		},
		delMeetDesc(index) {
			this.dataForm.meetingDesc.splice(index, 1);
		},
		addQualityProblemDescItem(subjectNo) {
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				descContent: '',
				dataType: '030102',
				subjectNo: subjectNo,
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			if (this.dataForm.qualityProblemDescList) {
				let isExistSubject = false;
				for (let i = 0; i < this.dataForm.qualityProblemDescList.length; i++) {
					if (this.dataForm.qualityProblemDescList[i].subjectNo == subjectNo) {
						this.dataForm.qualityProblemDescList[i].reportDescInfos.push(item);
						isExistSubject = true;
						break;
					}
				}
				if (!isExistSubject) {
					let group = {
						subjectNo: subjectNo,
						reportDescInfos: []
					}
					group.reportDescInfos.push(item)
					this.dataForm.qualityProblemDescList.push(group)
				}
			} else {
				let group = {
					subjectNo: subjectNo,
					reportDescInfos: []
				}
				group.reportDescInfos.push(item)
				this.dataForm.qualityProblemDescList = []
				this.dataForm.qualityProblemDescList.push(group)
			}
			this.addFlg = true;
		},
		deleteQualityProblemDescItem(subjectNo, index) {
			if (this.dataForm.qualityProblemDescList) {
				for (let i = 0; i < this.dataForm.qualityProblemDescList.length; i++) {
					if (this.dataForm.qualityProblemDescList[i].subjectNo == subjectNo) {
						this.dataForm.qualityProblemDescList[i].reportDescInfos.splice(index, 1);
					}
				}
			}
		},
		addPatrolCheckDescItem() {
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				descContent: '',
				dataType: '030201',
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			this.dataForm.patrolCheckDesc.push(item);
			this.addFlg = true;
		},
		deletePatrolCheckDescItem(index) {
			this.dataForm.patrolCheckDesc.splice(index, 1);
		},
		addProminentProblemDescItem(subjectNo) {
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				descContent: '',
				dataType: '030202',
				subjectNo: subjectNo,
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			if (this.dataForm.prominentProblemDescList) {
				for (let i = 0; i < this.dataForm.prominentProblemDescList.length; i++) {
					if (this.dataForm.prominentProblemDescList[i].subjectNo == subjectNo) {
						this.dataForm.prominentProblemDescList[i].reportDescInfos.push(item);
						return
					}
				}
				let group = {
					subjectNo: subjectNo,
					reportDescInfos: []
				}
				group.reportDescInfos.push(item)
				this.dataForm.prominentProblemDescList.push(group)
			} else {
				let group = {
					subjectNo: subjectNo,
					reportDescInfos: []
				}
				group.reportDescInfos.push(item)
				this.dataForm.prominentProblemDescList = []
				this.dataForm.prominentProblemDescList.push(group)
			}
			this.addFlg = true;
		},
		deleteProminentProblemDescItem(subjectNo, index) {
			if (this.dataForm.prominentProblemDescList) {
				for (let i = 0; i < this.dataForm.prominentProblemDescList.length; i++) {
					if (this.dataForm.prominentProblemDescList[i].subjectNo == subjectNo) {
						this.dataForm.prominentProblemDescList[i].reportDescInfos.splice(index, 1);
					}
				}
			}
		},
		addDesignEditDescItem() {
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				descContent: '',
				dataType: '050000',
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			this.dataForm.designEditDesc.push(item);
			this.addFlg = true;
		},
		deleteDesignEditDescItem(index) {
			this.dataForm.designEditDesc.splice(index, 1);
		},
		addHseInfoDescItem() {
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				descContent: '',
				dataType: '060000',
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			this.dataForm.hseInfoDesc.push(item);
			this.addFlg = true;
		},
		deleteHseInfoDescItem(index) {
			this.dataForm.hseInfoDesc.splice(index, 1);
		},
		addMeetingDescItem() {
			let item = {
				dataType: '070000',
				descContent: '',
				photoRecords: []
			};
			// this.dataForm.meetingDesc.push(item);
			this.meetDescList.push(item)
		},
		deleteMeetingDescItem(index) {
			this.meetDescList.splice(index, 1);
		},
		addOtherDescItem() {
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				descContent: '',
				dataType: '080000',
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			this.dataForm.otherDesc.push(item);
			this.addFlg = true;
		},
		deleteOtherDescItem(index) {
			this.dataForm.otherDesc.splice(index, 1);
		},
		goBack() {
			this.$router.back()
		},
		addConstructionProgressItem(subjectNo, major, index, index1) {
			let item = {
				weeklyRecNo: this.recNo,
				recNo: '',
				subjectNo: subjectNo,
				major: major,
				descContent: '',
				dataType: '020300',
				originalFlg: '0',
				updUserName: $common.getItem("userName"),
				updDate: this.getCurrentDate(),
				workNo: this.workNo
			};
			this.dataForm.constructionProgress[index].conProgressList[index1].descProgressList.push(item)
			this.addFlg = true;
		},
		deleteConstructionProgressItem(index) {
			this.dataForm.constructionProgress.splice(index, 1);
		},
		getWeekInfo() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/weeklyreportinfo/createWeekData",
				method: "get",
				params: {
					workNo: this.workNo,
					recNo: this.recNo
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					this.dataForm.beginTime = data.body.beginTime;
					this.dataForm.endTime = data.body.endTime;
					if (this.dataForm.meetingDesc) {
						this.meetDescList = this.dataForm.meetingDesc
					}

					// 2.3 详细进度情况
					let  constructionProgressList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let constructionProgress = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							conProgressList : []
						}
						constructionProgressList.push(constructionProgress)
						for (let j = 0; j < this.majorList.length; j++) {
							let major = this.majorList[j].displayValue
							let conProgressList = constructionProgressList[i].conProgressList
							let conProgress = {
								major : this.majorList[j].displayValue,
								majorName: this.majorList[j].displayName,
								descProgressList: []
							}
							conProgressList.push(conProgress);
							for (let k = 0; k < this.dataForm.constructionProgress.length; k++) {
								if(this.dataForm.constructionProgress[k].subjectNo === subjectNo
									&& this.dataForm.constructionProgress[k].major === major) {
										let descProgressList = constructionProgressList[i].conProgressList[j].descProgressList
										descProgressList.push(this.dataForm.constructionProgress[k])
								}
							}
						}
					}
					this.dataForm.constructionProgress = constructionProgressList

					// 3.1.2 主要质量问题跟踪处理情况
					let qualityProblemDescList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let qualityProblemDesc = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							reportDescInfos : [],
						}
						qualityProblemDescList.push(qualityProblemDesc)
						for (let k = 0; k < this.dataForm.qualityProblemDescList.length; k++) {
							if(this.dataForm.qualityProblemDescList[k].subjectNo === subjectNo) {
								qualityProblemDescList[i].reportDescInfos = this.dataForm.qualityProblemDescList[k].reportDescInfos
							}
						}
					}
					this.dataForm.qualityProblemDescList = qualityProblemDescList;

					// 3.2.2 突出问题跟踪处理情况
					let prominentProblemDescList = []
					for (let i = 0; i < this.subjectList.length; i++) {
						let subjectNo = this.subjectList[i].displayValue
						let prominentProblemDesc = {
							subjectNo : subjectNo,
							subjectName : this.subjectList[i].displayName,
							reportDescInfos : [],
						}
						prominentProblemDescList.push(prominentProblemDesc)
						for (let k = 0; k < this.dataForm.prominentProblemDescList.length; k++) {
							if(this.dataForm.prominentProblemDescList[k].subjectNo === subjectNo) {
								prominentProblemDescList[i].reportDescInfos = this.dataForm.prominentProblemDescList[k].reportDescInfos
							}
						}
					}
					this.dataForm.prominentProblemDescList = prominentProblemDescList;

					this.basicVisible = false;
					this.addFlg = false;
					this.updateFlg = false;
					this.updateDescRecNo= '';
				}
			});
		},
		uploadImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.progressPhotoRecords.push({
						photoFilePath: res.body.fileSavePath,
						photoPathUri: res.body.fileUri,
						thumbnailPath: res.body.thumbnailPath
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		delMeetPhoto(index1, index2) {
			this.meetDescList[index1].photoRecords.splice(index2, 1);
		},
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		dataFormSubmit() {
			this.dataForm.meetingDesc = this.meetDescList
			let _url = "/business/weeklyreportinfo/saveWeekData";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(this.dataForm)
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.$router.push({ name: 'weekly' });
						}
					});
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		jump_to_basic() {
			this.menuId = 'basic'
			this.$refs["basic"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_overview() {
			this.menuId = 'overview'
			this.$refs["overview"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_progress() {
			this.menuId = 'progress'
			this.$refs["progress"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_quality() {
			this.menuId = 'quality'
			this.$refs["quality"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_workFile() {
			this.menuId = 'workfile'
			this.$refs["workfile"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_design() {
			this.menuId = 'design'
			this.$refs["design"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_hse() {
			this.menuId = 'hse'
			this.$refs["hse"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_meet() {
			this.menuId = 'meet'
			this.$refs["meet"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_other() {
			this.menuId = 'other'
			this.$refs["other"].scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		},
		jump_to_enclosure() {
			this.menuId = 'enclosure'
			this.$refs["enclosure"].scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		},
	},

};
</script>
<style lang="scss" scoped="scoped">
.el-form-item {
	margin-bottom: 5px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.addImgBox200 {
	width: 200px;
	height: 200px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.elCardTitle {
	font-size: 18px;
	font-weight: bold;
	color: #00428e;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #00428e;
}

.selItemInput.descAreaLg {
	width: 1030px !important;
}

.selItemInput.descAreaLg1 {
	width: 430px !important;
}

.selItemInput.descAreaLg2 {
	width: 230px !important;
}

.time {
	font-size: 13px;
	color: #999;
}

.bottom {
	margin-top: 3px;
	line-height: 12px;
	vertical-align: bottom;
}

.button {
	padding: 0;
	float: right;
}

.image {
	width: 100%;
	height: 200px;
	margin-top: 5px;
	display: block;
}

.clearfix:after {
	display: table;
	content: "";
}

.clearfix:after {
	clear: both
}

.itemLabelspan {
	font-weight: bold;
	color: #606266;
	margin-right: 10px;
}

.itemLabelspanDetail {
	font-weight: bold;
	color: #606266;
	margin-right: 120px;
}

.itemLabeltext {
	color: #606266;
	margin-right: 10px;
}

.t-column-ellipsis {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	padding-bottom: 10px;
	height: 50px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
	// border-bottom: solid #dadada 1px;
	// border-left: solid #dadada 1px;
	// border-right: solid #dadada 1px;
	border: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 2px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}

.col-set_border1 {
	border: solid #dadada 1px;
}

.col-set_border2 {
	border: solid #dadada 1px;
	border-left: solid #dadada 0px;
}

.col-set_border3 {
	border: solid #dadada 1px;
	border-top: solid #dadada 0px;
}

.col-set_border4 {
	border-right: solid #dadada 1px;
	border-bottom: solid #dadada 1px;
}

.set_color::v-deep .el-textarea__inner {
	background-color: #e1f1ff !important;
}

.set_color1::v-deep .el-input__inner {
	background-color: #e1f1ff !important;
	// height:35px !important
}

.btn1 {
	bottom: 13px;
	margin-left: -60px;
	position: fixed;
}

.rebtn1 {
	bottom: 13px;
	position: fixed;
}

.set_bg {
	width: 65%;
	height: 50px;
	bottom: 6px;
	position: fixed;
	left: 20%;
	background-color: #f7f1f1;
}

.set_menu {
	position: fixed;
	cursor: pointer;
	left: 89%;
	font-size: smaller;
	color: #6d6c6c;
}

.set_btn {
	left: 88%;
	position: fixed;
	width: 9px;
	height: 9px;
	background-color: transparent;
	background: white;
	border-radius: 50%;
	cursor: pointer;
	border: 2px solid #81D3F8;
}

.set_btn_border {
	left: 88%;
	position: fixed;
	width: 9px;
	height: 9px;
	background-color: transparent;
	background: #81D3F8;
	border-radius: 50%;
	cursor: pointer;
	border: 2px solid #81D3F8;
}

.set_lewi {
	// width: 100px;
	font-weight: bold;
	color: #606266;
	margin-left: -5px;
	// font-weight: bold;
}

.el-icon-edit-outline {
	color: #169BD5;
	font-size: large;
	margin-left:20px;
	margin-top:178px;
	margin-right:2px;
	cursor: pointer
}

::v-deep(.sp-input .el-input__inner) {
	width: 140% !important;
}

::v-deep(.srt .el-input__inner) {
	width: 119% !important;
}

.el-icon-finished {
	color: #169BD5;
	font-size: large;
	margin-left:20px;
	margin-top:178px;
	margin-right:2px;
	cursor: pointer
}

.el-icon-takeaway-box {
	color: #169BD5;
	font-size: 16px;
	margin-left:20px;
	// margin-top:179px;
	margin-right:2px;
	cursor: pointer
}

.desc_zw {
	font-weight: 400;
    font-style: normal;
    font-size: 13px;
	color: white;
}

.desc_zw_bc {
	font-weight: 400;
    font-style: normal;
    font-size: 13px;
	color: #e1f1ff;
}

::v-deep .desc_input1 .el-textarea__inner {
    // padding-right: 50px !important;
     border: 0px; 
	 color: black;
	 resize: none;
}

.set_span {
	display: block; /* 将span转换为块级元素 */
	max-width: 275px; /* 设置固定长度 */
	white-space: nowrap; /* 不换行 */
	overflow: hidden; /* 超出部分隐藏 */
	text-overflow: ellipsis; /* 超出部分显示省略号 */
	cursor: default; /* 将鼠标光标设置为默认样式 */
}

.set_span1 {
	display: block; /* 将span转换为块级元素 */
	max-width: 710px; /* 设置固定长度 */
	white-space: nowrap; /* 不换行 */
	overflow: hidden; /* 超出部分隐藏 */
	text-overflow: ellipsis; /* 超出部分显示省略号 */
	cursor: default; /* 将鼠标光标设置为默认样式 */
}

::v-deep .desc_input_bc .el-textarea__inner {
    // padding-right: 50px !important;
     border: 0px; 
	 color: black;
	 background-color: #e1f1ff;
	 resize: none;
}
</style>
